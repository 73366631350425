/**
 * @name: 客流监测-客流记录接口文件
 * @author:lili
 * @date: 2023-08-04 17:46
 * @description： 客流监测-客流记录接口文件
 * @update: 2023-08-04 17:46
 */
import {get} from "@/request"
import {IPageRes} from "@/apis/page";
import {IPassengerDetailParam, IPassengerRecord, IPassengerRecordParam} from "@/apis/passenger/records/types";

/**
 * 今日客流
 * @returns
 */
export const todayPassengerFlowApi = () => get("/admin/passengerFlowRecord/getTodayPassengerFlow")


/**
 * 查询客流记录列表
 * @param params 查询参数
 * @returns
 */
export const passengerFlowRecordQueryApi = (params: IPassengerRecordParam) => get("/admin/passengerFlowRecord/query", params)

/**
 * 客流记录详情
 * @returns
 */
export const passengerFlowDetailApi = (param: IPassengerDetailParam) => get("/admin/passengerFlowRecord/getPassengerFlowDetail", param)

/**
 * 设备下拉列表
 * @returns
 */
export const deviceListApi = () => get("/admin/passengerFlowRecord/getDeviceList")



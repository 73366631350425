
/**
 * @name: 客流监测-客流记录
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 客流监测-客流记录
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import * as echarts from 'echarts'
import {passengerFlowDetailApi, passengerFlowRecordQueryApi, todayPassengerFlowApi} from "@/apis/passenger/records";
import {deepCopy} from "@/utils/common";
import {IPassengerDetailParam, IPassengerRecord, IPassengerRecordParam} from "@/apis/passenger/records/types";

@Component({})
export default class passengerRecords extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IPassengerRecordParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IPassengerRecord> = {}

  totalFlow: string = '0'
  // crud配置信息
  crudOption: ICrudOption = {
    menu: true,
    editBtn: false,
    delBtn: false,
    dialogWidth: '1600px',
    labelWidth: '150',
    column: [
      {
        label: "入口",
        prop: "deviceNo",
        align: "center",
        type: "select",
        search: true,
        dicUrl: '/api/admin/passengerFlowRecord/getDeviceList',
        dicFormatter: (res: any) => ({list: res.data, label: 'name', value: 'deviceNo'}),
        span:12,
        // dicData: [],
      },
      {
        label: "客流量",
        prop: "flowCount",
        align: "center",
        viewHide: true,
      },
      {
        label: "日期",
        prop: "createTime",
        align: "center",
        width: 150,
        search: true,
        searchSlot: true,
        span:12,
      },
      {
        label: "  ",
        prop: "flowList",
        align: "center",
        hide: true,
        viewSlot: true,
        span:24,
      },
    ]
  }
  todayList: any = []
  option: any = {
    title: {
      text: '今日客流'
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: [],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: ['1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00',
        '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']

    },
    yAxis: {
      type: 'value',
      data: ['50', '100', '150', '200', '250', '300', '350', '400', '500', '1000', '2000']
    },
    series: []
  }
  dialogTable: any = []

  /**
   * 今日客流
   * initChart
   */
  getTodayPassengerFlow() {
    todayPassengerFlowApi().then(e => {
      this.todayList = e
      //   [
      //   {
      //     name: "南大门",
      //     passengerFlowRecordVoList: [{
      //       flowCount: 11,
      //       hour: 12
      //     }]
      //   }
      // ];
      let series: any = [];
      this.todayList.forEach((e: any, i: number) => {
        this.option.legend.data.push(e.name);
        let obj = {
          data: [] as any,
          type: 'line',
          name: e.name
        }
        let k = 1;
        for (k; k <= 24; k++) {
          obj.data.push(0)
          e.passengerFlowRecordVoList.forEach((item: any) => {
            if (k == item.hour) {
              obj.data.splice(k - 1, 1, item.flowCount)
            }

          })
        }
        series.push(obj)
      });
      this.$nextTick(() => {
        this.option.series = series;
        this.initChart();
      })
    })
  }

  /**
   *
   * 初始echarts
   */
  initChart() {
    let myChart = echarts.init(document.getElementById("myChart"));
    // 绘制图表
    myChart.setOption(this.option);
  }

  /**
   *详情
   * @param row
   */
  handleDetail(row: IPassengerRecord, index: number) {
    this.dialogTable=[];
    let obj: IPassengerDetailParam = {
      createTime: row.createTime,
      deviceNo: row.deviceNo
    }
    this.modelForm = row;
    passengerFlowDetailApi(obj).then(e => {
      if (e) {
        let obj: any = {
          label0: '时刻',
          value0: '客流量'
        }
        let k = 1;
        for (k; k <= 24; k++) {
          obj[`label${k}`] = `${k}:00`;
          let flowCount = 0;
          e.forEach((item: any) => {
            if (k == item.hour) {
              flowCount = item.flowCount;
            }
          })
          obj[`value${k}`] = flowCount;
        }
        this.dialogTable.push(obj)

      }
      // @ts-ignore
      this.$refs.crudRef.rowView(this.modelForm, index);
    })
  }

  getList() {
    const form: IPassengerRecordParam = deepCopy(this.queryParam);
    if (this.queryParam.createTime && this.queryParam.createTime.length) {
      form.startTime = this.queryParam.createTime[0]
      form.endTime = this.queryParam.createTime[1]
    } else {
      form.startTime = ""
      form.endTime = ""
    }
    delete form.createTime
    passengerFlowRecordQueryApi(form).then(e => {
      this.totalFlow = e.totalFlow || 0;
      let {passengerFlowRecordVoCommonPage} = e;
      //@ts-ignore
      this.tableData = passengerFlowRecordVoCommonPage.list;
      this.tableTotal = passengerFlowRecordVoCommonPage.total
    })
  }

  created() {
    this.getList()
  }

// 页面加载时
  mounted() {
    this.getTodayPassengerFlow();
  }
}

